import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { GoogleMapsModule } from '@angular/google-maps'

import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbAutocompleteModule } from 'mdb-angular-ui-kit/autocomplete';
import { MdbChipsModule } from 'mdb-angular-ui-kit/chips';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbPopconfirmModule } from 'mdb-angular-ui-kit/popconfirm';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';


import * as common from './common';
import * as components from './components';
import * as modals from './modals';
import * as utils from './utils';
import * as services from './services';
import * as directives from './directives';
import * as interceptors from './interceptors';

@NgModule({
  declarations: [
    AppComponent,
    common.LoaderComponent,
    common.HeaderComponent,
    common.FooterComponent,
    components.LayoutMainComponent,
    components.MainBodyComponent,
    components.SearchListingComponent,
    components.GetDirectionComponent,
    components.ContactPopupComponent,
    modals.SearchModalComponent,
    modals.DetailModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MdbAutocompleteModule,
    ReactiveFormsModule,
    MdbCollapseModule,
    MdbSelectModule,
    MdbRippleModule,
    NgxPaginationModule,
    MdbChipsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CarouselModule,
    MdbModalModule,
    GoogleMapsModule,
    MdbTooltipModule,
    MdbPopconfirmModule,
    MdbRadioModule,
    MdbTabsModule,
  ],
  providers: [
    services.HttpClientService,
    services.MainService,
    utils.DateUtility,
    utils.StorageUtility,
    utils.HolistaUtils,
    utils.CurrencyFormatUtility,
    directives.DebounceClickDirective,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.HeaderInterceptor,
      multi: true
    },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
