<div class="popconfirm-popover shadow-4">
  <div class="popconfirm">
    <h6 class="text-primary">Contact</h6>
    <p class="popconfirm-message m-0">
      <!-- <span class="m-0 lh-sm">
        <a href="tel:+{{networkConfig?.phone}}" class="d-flex align-items-center justify-content-start text-reset">
          <i class="fas fa-mobile-alt text-primary"></i>
          &nbsp; <span class="fst-normal ms-1">{{networkConfig?.phone ? (networkConfig?.phone | mask: '000.000.0000') :
            "N/A"}}</span>
        </a>
      </span> -->
      <span class="m-0 lh-sm">
        <a href="mailto:{{networkConfig?.email}}" class="d-flex align-items-center justify-content-start text-reset">
          <i class="fas fa-envelope text-primary"></i>
          &nbsp; <span class="fst-normal ms-1">{{networkConfig?.email ? networkConfig.email : "N/A"}}</span>
        </a>
      </span>
    </p>
    <span class="close-marker cursor-pointer" (click)="popconfirmRef.close()">
      <i class="text-danger fa-solid fa-circle-xmark fa-lg"></i>
    </span>
  </div>
</div>
