import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ComponentUtils {
  constructor() { }
  public getComponentHeight(componentName: string) {
    const container = document.querySelector(componentName);
    if (container) {
      return container.getBoundingClientRect().height;
    }
    return 0;
  }
}
