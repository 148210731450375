import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GetDirectionComponent, LayoutMainComponent } from './components';
import { RoutePublic } from './routes/route.public';

const routes: Routes = [
  { path: "", component: LayoutMainComponent, children: RoutePublic },
  { path: "get-direction", component: GetDirectionComponent },
  { path: "**", redirectTo: "/" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
