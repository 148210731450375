import { Injectable } from '@angular/core';
import { HOLISTA_CONSTANT } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})

export class LocationUtils {
  DEFAULT_LOCATION = HOLISTA_CONSTANT.DEFAULT_LOCATION;
  constructor() { }
  public getLocationDetails(location: any) {
    const defaultLocation = this.DEFAULT_LOCATION;
    return new Promise((resolve) => {
      const loc = {
        lat: null,
        lng: null,
        city: null,
        state: null,
        zip: null,
        displayName: null,
      };

      if (Math.round(location.coords.longitude) === 85) {
        loc.lat = defaultLocation.lat;
        loc.lng = defaultLocation.lng;
        loc.city = defaultLocation.city;
        loc.state = defaultLocation.state;
        loc.zip = defaultLocation.zip;
        loc.displayName = defaultLocation.displayName;
        resolve(loc);
      } else {
        loc.lat = location.coords.latitude;
        loc.lng = location.coords.longitude;
        const geocoder = new (window as any).google.maps.Geocoder({
          fields: [
            'name',
            'address_components',
            'formatted_address',
            'geometry.location',
          ],
        });
        const latlng = new (window as any).google.maps.LatLng(loc.lat, loc.lng);
        geocoder.geocode({ location: latlng }, (results: any, status: string) => {
          if (status === 'OK') {
            results.forEach((result: any) => {
              if (!!result && result !== 'null') {
                result.address_components.forEach((x: any) => {
                  if (x.types[0] === 'locality') {
                    loc.city = `${x.long_name}`;
                  }
                  if (x.types[0] === 'administrative_area_level_1') {
                    loc.state = `${x.short_name}`;
                  }
                  if (x.types[0] === 'postal_code') {
                    loc.zip = `${x.long_name}`;
                  }
                });
              }
            });
            loc.displayName = loc?.city ? `${loc.city}, ${loc.state}` : '';
            resolve(loc);
          }
        });
      }
    })
  }

  public getDirection(from: string = '', to: string = '') {
    const url = `${HOLISTA_CONSTANT.GOOGLE_MAPS_URL}/${from}/${to}`;
    window.open(url, '_blank');
  }

}
