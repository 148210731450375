<div class="h-100" *ngIf="!loading.networkConfig">
  <div class="h-100">
    <div class="h-100">
      <div class="h-100 main-body-wrapper">
        <div class="header sticky-top">
          <app-header [config]="config"></app-header>
        </div>
        <div class="main-body" [ngStyle]="{ height: mainSearchHeight }">
          <app-main-body (isMainPage)="checkIsMainPage($event)"></app-main-body>
        </div>
        <div class="footer" class="footer fixed-bottom">
          <app-footer [config]="config"></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex align-items-center justify-content-center h-100" *ngIf="loading.networkConfig">
  <app-loader [loader]="true"></app-loader>
</div>