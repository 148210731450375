import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class HolistaUtils {
  constructor() { }
  public customRedirect(link: string, newTab = true) {
    if (!link) return;
    if (!link.includes('http://') && !link.includes('https://')) {
      link = `https://${link}`;
    }
    window.open(link, newTab ? '_blank' : '_self');
  }

  public sortBy(arr: any, sortBy: string): any {
    arr = arr.sort(function (a: any, b: any) {
      if (a[sortBy] < b[sortBy]) {
        return -1;
      } else if (a[sortBy] > b[sortBy]) {
        return 1;
      } else {
        return 0;
      }
    });
    return arr;
  }

  /**
   * deep clones array or object
   * @param obj
   * @returns
   */
  // NOTE: This does not work for objects with function or circular dependency. Perhaps use Ramda or lodash?
  // structuredClone can be used for Node v17 or higher
  deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  toTitleCase(data: string) {
    return data.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  titleCasing(value: any, symbol?: any): any {
    if (symbol) {
      return value.split(symbol).map((word: string) => this.toTitleCase(word)).join(symbol);
    }
    return this.toTitleCase(value);
  }

  /**
   * Generate Year Option List
   * @param startYear
   * @param endYear
   * @returns array of object(s)
   */
  getYearOptionList(startYear: number, endYear: number) {
    let yearList = [];
    for (let i = startYear; i <= endYear; i++) {
      yearList.push({ label: i.toString(), value: i });
    }
    return yearList;
  }

  convertUndefinedToNull(obj: any) {
    const updatedObject = { ...obj };
    for (const key in updatedObject) {
      if (updatedObject.hasOwnProperty(key) && updatedObject[key] === undefined) {
        updatedObject[key] = null;
      }
    }
    return updatedObject;
  }

  removeNullOrUndefinedKeys(obj: any) {
    const result: any = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined) {
        result[key] = obj[key];
      }
    }
    return result;
  }

  getFullName(firstname:string, middlename:string, lastname:string) {
    return [firstname, middlename, lastname].filter(Boolean).join(' ');
  }
}
