import { Component, Input, OnInit } from '@angular/core';
import { HOLISTA_CONSTANT } from 'src/app/constants';
import { DateUtility, HolistaUtils } from 'src/app/utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  HOLISTA_CONSTANT = this._holistaUtils.deepClone(HOLISTA_CONSTANT);
  currentYear = this._dateUtility.getCurrentYear();
  networkConfig: any;
  @Input() set config(data) {
    if (data?.code) {
      this.networkConfig = data;
    }
  };

  constructor(
    public _dateUtility: DateUtility,
    public _holistaUtils: HolistaUtils,
  ) { }

  onLinkClick(link: string, newTab?: boolean) {
    this._holistaUtils.customRedirect(link, newTab);
  }

}
