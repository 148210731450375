import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { StorageUtility } from 'src/app/utils';

@Component({
  selector: 'app-get-direction',
  templateUrl: './get-direction.component.html',
  styleUrls: ['./get-direction.component.scss']
})
export class GetDirectionComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  infoContent: any
  mapData: any;
  currentLocation: any;
  width: string = '100%';
  height: string = '100%';
  markers: any[] = [];
  center: any = { lat: 0, lng: 0 };
  zoom = 12;
  vertices: google.maps.LatLngLiteral[] = [];
  polylineOptions = {
    geodesic: true,
    strokeColor: "#3c9584",
    strokeOpacity: 0.7,
    strokeWeight: 4,
  };
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    // disableDoubleClickZoom: true,
  };
  coordinates = [];

  constructor(
    private _storageUtility: StorageUtility,
  ) { }

  ngOnInit(): void {
    const currentLocation = this._storageUtility.get('local', 'currentLocation');
    this.currentLocation = currentLocation;
    this.coordinates.push(currentLocation.position);
    const data = this._storageUtility.get('local', 'mapData');
    this.mapData = data;
    if (data?.facility?.location?.coordinates.length) {
      this.coordinates.push({
        lat: data?.facility?.location?.coordinates[1] || 0,
        lng: data?.facility?.location?.coordinates[0] || 0,
      })
      this.addMarker();
    }
  }

  ngOnDestroy() {
    this._storageUtility.remove('local', 'currentLocation');
    this._storageUtility.remove('local', 'mapData');
  }

  addMarker() {
    const { facility } = this.mapData;
    const { position, ...rest } = this.currentLocation;
    // Position of markers
    this.markers = [
      {
        position: this.coordinates[0],
        locData: { ...rest, }
      },
      {
        position: this.coordinates[1],
        locData: {
          ...facility.address,
          displayName: facility.displayName || facility.businessName || ''
        }

      },
    ];
    // Center the view in the map
    this.center = this.coordinates[0];

    // This is to connect line from one marker to another
    this.vertices = [
      this.coordinates[0],
      this.coordinates[1]
    ]
  }

  openInfo(marker, data) {
    this.infoWindow.open(marker, true);
    this.infoContent = data.locData;
  }

}
