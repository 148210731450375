<section class="text-center text-lg-start bg-white ">
  <section class="container container-outer">
    <section class="px-4 py-2">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
          <small class="row fst-normal">
            <span class="col-sm-12 col-md-12 col-lg-3 p-0 text-reset">&copy; {{currentYear}}
              <span class="cursor-pointer" (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_WEBSITE)">
                Holista Health
              </span>
            </span>
            <span class="col-sm-12 col-md-12 col-lg-9 text-decoration-underline">
              <span class="text-primary cursor-pointer"
                (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_PRIVACY_POLICY)">Privacy Policy
              </span> |
              <span class="text-primary cursor-pointer"
                (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_TERMS_AND_CONDITION)">Terms &
                Conditions
              </span>
            </span>
          </small>
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <div class="row">
            <span class="col-sm-12 col-md-12 col-lg-12">
              <small
                class="d-flex align-items-center justify-content-center justify-content-lg-end text-reset cursor-pointer"
                (click)="onLinkClick(networkConfig?.website)">
                <i class="fas fa-globe text-primary"></i>
                &nbsp; <span class="fst-normal ms-1">{{networkConfig?.website ? networkConfig.website : "N/A"}}</span>
              </small>
            </span>
          </div>
        </div>
      </div>
    </section>
  </section>
</section>
