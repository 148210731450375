import { Injectable } from "@angular/core";

@Injectable()

export class StorageUtility {
  public get(type: string, key: string, value?: string) {
    if (type == 'local') {
      if (typeof localStorage !== 'undefined') {
        let data: any = localStorage.getItem(key)
        if (data)
          data = JSON.parse(data)
        return value ? value.includes('.') ? data[value.split('.')[0]][value.split('.')[1]] : data[value] : data
      }
      return '';
    } else {
      if (typeof sessionStorage !== 'undefined') {
        let data: any = sessionStorage.getItem(key)
        if (data)
          data = JSON.parse(data)
        return value ? value.includes('.') ? data[value.split('.')[0]][value.split('.')[1]] : data[value] : data
      }
      return '';
    }
  }

  public set(type: string, key: string, value: any) {
    if (type === 'local') {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } else {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    }
  }

  public clear(type: string) {
    if (type == 'local') {
      if (typeof localStorage !== 'undefined') {
        localStorage.clear();
      }
    } else {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.clear();
      }
    }
  }

  public remove(type: string, key: string) {
    if (type == 'local') {
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(key);
      }
    } else {
      if (typeof sessionStorage !== 'undefined') {
        sessionStorage.removeItem(key);
      }
    }
  }
}
