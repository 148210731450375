<div class="modal-header">
  <p class="modal-title fs-6">Edit Your Search</p>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeSearchModal(true)"></button>
</div>
<div class="modal-body">
  <div>
    <form class="mx-auto w-100" [formGroup]="mainSearchForm">
      <div class="d-flex position-relative justify-content-between" style="top: -13px;">
        <div class="form-check form-check-inline p-0">
          <p class="h6 m-0">Search by:</p>
        </div>
        <div class="form-check form-check-inline p-0">
          <input class="form-check-input" type="radio" name="searchBy" id="provider1" value="provider"
            formControlName="searchBy" (change)="changeSearchBy($event)" />
          <label class="form-check-label" for="provider1">Provider</label>
        </div>
        <div class="form-check form-check-inline p-0">
          <input class="form-check-input" type="radio" name="searchBy" id="service1" value="service"
            formControlName="searchBy" (change)="changeSearchBy($event)" />
          <label class="form-check-label" for="service1">Service</label>
        </div>
      </div>
      <div class="row bg-white g-1 p-2 custom-rounded" style="box-shadow: 0px 0px 10px 0px #cbcbcb">
        <!-- Provider Auto Complete Starts -->
        <div class="bundle col-12 col-sm-7 col-md-7 col-lg-7 m-0" *ngIf="isSearchByProvider">
          <mdb-form-control>
            <i class="icon fas fa-magnifying-glass text-gray"></i>
            <input mdbInput formControlName="provider" (ngModelChange)="searchProviderText.next($event)"
              [mdbAutocomplete]="autocompleteProvider" type="text" id="autocompleteProvider" class="form-control m-0"
              placeholder="Search by Provider" />
            <div *ngIf="displayClearButton.provider" class="autocomplete-loader cursor-pointer" role="status"
              (click)="clearAutocompleteSearch()">
              <i class="fa-solid fa-xmark text-primary"></i>
            </div>
          </mdb-form-control>
          <mdb-autocomplete #autocompleteProvider="mdbAutocomplete" [displayValue]="onBundleDisplay">
            <mdb-option *ngFor="let option of providerList" [value]="option" (click)="onBundleOptionSelected(option)">
              {{ option.label }}
            </mdb-option>
            <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
            <div *ngIf="isLoading.bundles" class="autocomplete-no-results"><small>Loading...</small></div>
          </mdb-autocomplete>
        </div>
        <!-- Provider Auto Complete Ends -->
        <!-- Service Auto Complete Starts -->
        <div class="bundle col-12 col-sm-7 col-md-7 col-lg-7 m-0" *ngIf="!isSearchByProvider">
          <mdb-form-control>
            <i class="icon fas fa-magnifying-glass text-gray"></i>
            <input mdbInput formControlName="bundle" (ngModelChange)="searchBundleText.next($event)"
              [mdbAutocomplete]="autocompleteBundle" type="text" id="autocompleteBundle" class="form-control m-0"
              placeholder="Search by Procedure Name" />
            <div *ngIf="displayClearButton.service" class="autocomplete-loader cursor-pointer" role="status"
              (click)="clearAutocompleteSearch()">
              <i class="fa-solid fa-xmark text-primary"></i>
            </div>
          </mdb-form-control>
          <mdb-autocomplete #autocompleteBundle="mdbAutocomplete" [displayValue]="onBundleDisplay">
            <mdb-option *ngFor="let option of bundleList" [value]="option" (click)="onBundleOptionSelected(option)">
              {{ option.label }}
            </mdb-option>
            <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
            <div *ngIf="isLoading.bundles" class="autocomplete-no-results"><small>Loading...</small></div>
          </mdb-autocomplete>
        </div>
        <!-- Service Auto Complete Starts -->
        <!-- Location Auto Complete Starts -->
        <div class="location col-12 col-sm-3 col-md-3 col-lg-3 m-0 border-start">
          <mdb-form-control>
            <i class="icon fas fa-location-dot text-gray"></i>
            <input mdbInput formControlName="location" (ngModelChange)="searchLocationText.next($event)"
              [mdbAutocomplete]="autocompleteLocation" type="text" id="autocompleteLocation" class="form-control m-0"
              placeholder="Enter Location" />
            <button mdbRipple class="icon-right btn btn-white btn-floating bg-white" mdbTooltip="Use current location"
              (click)="handleSelectCurrentLocationClick()">
              <i class="fa-solid fa-location-crosshairs text-primary fa-lg"></i>
            </button>
          </mdb-form-control>
          <mdb-autocomplete #autocompleteLocation="mdbAutocomplete" [displayValue]="onLocationDisplay">
            <mdb-option *ngFor="let option of locationList" [value]="option" (click)="onLocationOptionSelected(option)">
              {{ option.label }}
            </mdb-option>
            <div *ngIf="notFound" class="autocomplete-no-results"><small>No results found</small></div>
            <div *ngIf="isLoading.locations" class="autocomplete-no-results"><small>Loading...</small></div>
          </mdb-autocomplete>
        </div>
        <!-- Location Auto Complete Ends -->
        <div class="search col-12 col-sm-2 col-md-2 col-lg-2 m-0">
          <button mdbRipple type="button"
            class="d-flex align-items-center justify-content-center btn btn-primary w-100 rounded disabled ripple-surface"
            [class.disabled]="mainSearchForm.invalid" (click)="closeSearchModal(false)">
            <i class="fas fa-search"></i>&nbsp;
            <small class="text-capitalize fw-normal fs-6">
              Search
            </small>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>