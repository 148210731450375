<div *ngIf="loader" class="d-flex justify-content-center">
  <div class="spinner-grow text-primary" [class.spinner-grow-sm]="loaderSmall" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-info" [class.spinner-grow-sm]="loaderSmall" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-success" [class.spinner-grow-sm]="loaderSmall" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
