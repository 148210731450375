import { Injectable } from "@angular/core";
import * as moment from 'moment';

@Injectable()

export class DateUtility {

  /**
   * returns current year
   */
  getCurrentYear() {
    return moment().year();
  }

}
