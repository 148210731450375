import { formatCurrency } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable()

export class CurrencyFormatUtility {
  public format(num): any {
    return ((num || num === 0) ? formatCurrency(Number(num), 'en', '$') : '-');
  }

  public parseCurrency(currencyString) {
    return Number(currencyString.replace(/[$,]/g, ''));
  }

}
