<div class="listing-search">
  <div>
    <div *ngIf="showSuggestions && !loadingProviders && chipList.length">
      <p class="mt-4 small text-muted">{{ chipList.length ? 'Did you mean?' : 'No results found' }}</p>
      <mdb-chip *ngFor="let item of chipList" (click)="bundleOptionSelected(item, true)"
        customClass="chip chip-outline btn-outline-secondary bg-white d-inline-block">
        {{ item?.displayName || item?.bundleName || item?.businessName || item?.fullName}}
      </mdb-chip>
    </div>
    <div *ngIf="!showSuggestions">
      <div class="mt-3">
        <div class="row my-2">
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 total">
            <p class="small text-muted m-0 mb-1">
              <span *ngIf="loadingProviders || mainSearchFiltersData?.providers?.length; else noResults">{{
                mainSearchFiltersData?.providers?.length }} Result{{ mainSearchFiltersData?.providers?.length > 1 ? 's'
                : '' }} found
                for <strong>{{mainSearchFiltersData?.bundle?.displayName || mainSearchFiltersData?.bundle?.bundleName ||
                  mainSearchFiltersData?.bundle || mainSearchFiltersData?.provider?.displayName ||
                  mainSearchFiltersData?.provider?.businessName
                  || mainSearchFiltersData?.provider?.fullName ||
                  mainSearchFiltersData?.provider }}</strong></span>
            </p>
            <ng-template #noResults>
              <span>No results found</span>
            </ng-template>
          </div>
          <form class="col-12 col-sm-4 col-md-4 col-lg-4" [formGroup]="searchAdvanceFilterForm">
            <div
              class="d-flex justify-content-sm-between justify-content-md-end justify-content-lg-end align-items-center filters">
              <!-- <p class="d-flex justify-content-between align-items-center small text-muted m-0">
                <span class="me-2">Filter by Doctor</span>
                <mdb-form-control class="me-2">
                  <mdb-select formControlName="doctor" placeholder="Choose Doctor"
                    (selected)="onDoctorSelected($event)">
                    <mdb-option *ngFor="let option of doctors" [value]="option.value">{{
                      option.label
                      }}</mdb-option>
                  </mdb-select>
                </mdb-form-control>
              </p> -->
              <p class="d-flex justify-content-between align-items-center small text-muted m-0">
                <span class="me-2">Radius</span>
                <mdb-form-control>
                  <mdb-select formControlName="radius" placeholder="Distance" (selected)="onRadiusSelected($event)"
                    style="width: 140px;">
                    <mdb-option *ngFor="let option of radius" [value]="option.value">
                      {{option.label}}
                    </mdb-option>
                  </mdb-select>
                </mdb-form-control>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div class="class-container">
        <!-- <div *ngFor="let item of mainSearchFiltersData?.providers" class="card mb-2">
          <div class="card-body py-2">
            <div class="row">
              <div class="col-sm-12 col-md-4 col-lg-4 m-0 p-0">
                <div class="d-flex">
                  <div class="contact-img me-2">
                    <i class="mx-auto d-block img-fluid rounded-circle shadow-4 fas fa-hospital fa-2x text-primary d-flex align-items-center justify-content-center"
                      style="height: 60px; width: 60px;"></i>
                  </div>
                  <div class="contact-info">
                    <div class="name-speciality">
                      <p class="h6 m-0 mt-1">{{ item?.facility?.displayName || item?.facility?.businessName }}</p>
                      <p class="m-0 text-light">
                        <small>{{ item?.facility?.primaryTaxonomy && item?.facility?.primaryTaxonomy[0]?.name }}</small>
                      </p>
                    </div>
                    <div class="address-phone mt-3">
                      <div class="address d-flex">
                        <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                        <p class="m-0">
                          <small class="text-gray">{{ item?.facility?.address?.addressLine1 }}</small><br>
                          <small class="text-gray">{{ item?.facility?.address?.city }}, {{
                            item?.facility?.address?.state
                            }}, {{ item.facility?.address?.zip }}</small><br>
                          <small class="text-primary text-decoration-underline cursor-pointer"
                            (click)="openLocationModal(item)">(Get Directions)</small>
                        </p>
                      </div>
                      <div class="phone d-flex align-items-center">
                        <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                        <p class="m-0">
                          <small class="text-gray">{{ item?.facility?.phone | mask: '000.000.0000' }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="hr d-block d-md-none d-lg-none mt-3">
              <div class="col-sm-12 col-md-8 col-lg-8 m-0 p-0">
                <owl-carousel-o [options]="customOptions">
                  <ng-container *ngFor="let slideItem of mainSearchFiltersData.physicians; let i = index">
                    <ng-template carouselSlide let-owlItem>
                      <div
                        [ngClass]="{ 'middle-slide': owlItem.isCentered, 'first-slide': i=== 0 && mainSearchFiltersData.physicians.length < 3 }"
                        class="mt-3 item">
                        <i class="mx-auto d-block img-fluid rounded-circle text-primary shadow-4 fas fa-user-md fa-2x d-flex align-items-center justify-content-center"
                          style="height: 60px; width: 60px;"></i>
                        <p class="h6 text-dark text-center m-0 mt-2">{{ slideItem?.firstName }} {{ slideItem?.lastName
                          }}
                        </p>
                        <p class="text-light text-center"><small>
                            {{ slideItem?.primaryTaxonomy && slideItem.primaryTaxonomy[0].name }}
                          </small></p>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row g-4">
          <div class="col-12 col-sm-6 col-lg-3"
            *ngFor="let item of mainSearchFiltersData?.providers | paginate: {itemsPerPage: listingSearchFilter?.pagination?.limit, currentPage: listingSearchFilter?.pagination?.page, totalItems: listingSearchFilter?.pagination?.totalCount}">
            <div class="card position-relative" [style.min-height]="isSmallScreen ? 'auto' : getHeight(item?.facility)">
              <div class="card-body">
                <div class="contact-img">
                  <i class="d-block img-fluid rounded-circle shadow-4 border fas fa-hospital fa-lg text-primary d-flex align-items-center justify-content-center mb-2"
                    style="height: 50px; width: 50px;"></i>
                </div>
                <!-- For Facility-->
                <div class="contact-info" *ngIf="item?.facility">
                  <div class="name-speciality">
                    <p class="h6 m-0 mt-3 cursor-pointer"
                      [mdbTooltip]="item?.facility?.displayName || item?.facility?.businessName">
                      {{ item?.facility?.displayName || item?.facility?.businessName | slice:0:50 }}
                      <span
                        *ngIf="item?.facility?.displayName?.length > 50 || item?.facility?.businessName?.length > 50">...</span>
                    </p>
                    <p class="m-0 text-light">
                      <small>{{ item?.facility?.primaryTaxonomy && item?.facility?.primaryTaxonomy[0]?.name
                        }}</small>
                    </p>
                  </div>
                  <div class="address-phone mt-1">
                    <div class="address d-flex">
                      <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
                      <p class="m-0">
                        <small class="text-gray">{{ item?.facility?.address?.addressLine1 }}</small><br>
                        <small class="text-gray">{{ item?.facility?.address?.city }}, {{
                          item?.facility?.address?.state
                          }}, {{ item.facility?.address?.zip }}</small><br>
                        <small class="text-primary text-decoration-underline cursor-pointer"
                          (click)="getDirection(item)">(Get Directions)</small>
                      </p>
                    </div>
                    <div class="phone mt-2" *ngIf="item?.facility?.phone">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                          <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                          <p class="m-0">
                            <small class="text-gray">{{ item?.facility?.phone ? (item.facility.phone | mask:
                              '000.000.0000')
                              : 'N/A' }}</small>
                          </p>
                        </div>
                        <div>
                          <button type="button" (click)="viewDetailModal(item)"
                            class="d-block d-sm-none d-md-none d-lg-none btn btn-outline-dark bg-white text-capitalize rounded"
                            style="border: 1px solid #ddd" mdbRipple rippleColor="dark">
                            View Detail
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- For Physician -->
                <div class="contact-info" *ngIf="item?.physician">
                  <div class="name-speciality">
                    <p class="h6 m-0 mt-3 cursor-pointer"
                      [mdbTooltip]="item?.physician?.displayName || item?.physician?.businessName || getFullName(item?.physician)">
                      {{ item?.physician?.displayName || item?.physician?.businessName || getFullName(item?.physician) | slice:0:50 }}
                      <span
                        *ngIf="item?.physician?.displayName?.length > 50 || item?.physician?.businessName?.length > 50 || getFullName(item?.physician).length > 50">...</span>
                    </p>
                    <p class="m-0 text-light">
                      <small>{{ item?.physician?.primaryTaxonomy && item?.physician?.primaryTaxonomy[0]?.name
                        }}</small>
                    </p>
                  </div>
                </div>
              </div>
              <button type="button" (click)="viewDetailModal(item)"
                class="d-none d-sm-block d-md-block d-lg-block btn btn-outline-dark bg-white text-capitalize rounded m-2 position-absolute"
                style="border: 1px solid #ddd; bottom: 10px; left: 10px; right: 10px;" mdbRipple rippleColor="dark">
                View Detail
              </button>
            </div>
          </div>
        </div>
        <div class="mt-3 d-none d-sm-block d-md-block d-lg-block"
          *ngIf="!loadingProviders && mainSearchFiltersData?.providers?.length">
          <pagination-controls class="text-center" (pageChange)="pageChanged($event)" [autoHide]="true">
          </pagination-controls>
        </div>
        <div class="mt-3 text-center d-block d-sm-none d-md-none d-lg-none"
          *ngIf="!loadingProviders && mainSearchFiltersData?.providers?.length &&  mainSearchFiltersData?.providers?.length > HOLISTA_CONSTANT.defaultPaginationQuery.limit">
          <button [disabled]="listingSearchFilter?.pagination?.totalCount === loadMorePageCount" type="button"
            class="btn btn-outline-dark bg-white text-capitalize rounded-pill" style="border: 1px solid #ddd" mdbRipple
            rippleColor="dark" (click)="pageChanged(null, true)">
            Load More
          </button>
        </div>
      </div>
    </div>
    <div class="my-3" *ngIf="loadingProviders">
      <app-loader [loader]="true"></app-loader>
    </div>
  </div>
</div>