import { Component, OnInit } from '@angular/core';
import { MdbPopconfirmRef } from 'mdb-angular-ui-kit/popconfirm';
import { StorageUtility } from 'src/app/utils';

@Component({
  selector: 'app-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent implements OnInit {
  networkConfig: any;
  constructor(
    public popconfirmRef: MdbPopconfirmRef<ContactPopupComponent>,
    public _storageUtility: StorageUtility,
  ) {
  }

  ngOnInit(): void {
    this.networkConfig = this._storageUtility.get('local', 'networkConfig');
  }

}
