<div class="my-google-map" style="height: 100vh;">
  <google-map #mapel [width]="width" [center]="center" [zoom]="zoom" [options]="options">
    <map-marker #somemarker="mapMarker" *ngFor="let marker of markers;" [position]="marker.position"
      [label]="marker.label" [title]="marker.title" (mapClick)="openInfo(somemarker, marker)">
      <map-info-window>
        <div>
          <div class="poi-info-window gm-style">
            <div>
              <div class="title full-width">{{ infoContent?.displayName }}</div>
              <div class="address">
                <div class="address-line full-width">
                  {{ infoContent?.addressLine1 }}
                </div>
                <div class="address-line full-width">
                  {{ infoContent?.city }}, {{ infoContent?.state }} {{ infoContent?.zip }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </map-info-window>
    </map-marker>
    <map-polyline [options]="polylineOptions" [path]="vertices">
    </map-polyline>
  </google-map>
</div>
