<button type="button" class="btn-close position-absolute" aria-label="Close" (click)="modalRef.close()"
  style="right: 10px; top: 10px; z-index: 1;"></button>
<div class="modal-body scrollbar scrollbar-primary thin" style="max-height: 550px; min-height: 550px;">
  <div>
    <div class="d-flex card-body">
      <div class="contact-img">
        <i class="d-block img-fluid rounded-circle shadow-4 border fas fa-hospital fa-lg text-primary d-flex align-items-center justify-content-center mb-2"
          style="height: 50px; width: 50px;"></i>
      </div>
      <!-- For Facility -->
      <div class="contact-info ms-2 me-4" *ngIf="providerData?.facility">
        <div class="name-speciality">
          <p class="h6 m-0 cursor-pointer"
            [mdbTooltip]="providerData?.facility?.displayName || providerData?.facility?.businessName">
            {{ providerData?.facility?.displayName || providerData?.facility?.businessName | slice:0:50 }}
            <span
              *ngIf="providerData?.facility?.displayName?.length > 50 || providerData?.facility?.businessName?.length > 50">...</span>
          </p>
          <p class="m-0 text-light">
            <small>{{ providerData?.facility?.primaryTaxonomy && providerData?.facility?.primaryTaxonomy[0]?.name
              }}</small>
          </p>
        </div>
        <div class="address-phone mt-1">
          <div class="address d-flex">
            <i class="fa-solid fa-location-dot fa-sm text-light me-2" style="margin-top: 13px;"></i>
            <p class="m-0">
              <small class="text-gray">{{ providerData?.facility?.address?.addressLine1 }}</small><br>
              <small class="text-gray">{{ providerData?.facility?.address?.city }}, {{
                providerData?.facility?.address?.state
                }}, {{ providerData.facility?.address?.zip }}</small><br>
              <small class="text-primary text-decoration-underline cursor-pointer"
                (click)="getDirection(providerData)">(Get Directions)</small>
            </p>
          </div>
          <div class="phone mt-2">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i>
                <p class="m-0">
                  <small class="text-gray">{{ providerData?.facility?.phone ? (providerData.facility.phone | mask:
                    '000.000.0000')
                    : 'N/A' }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- For Physician -->
      <div class="contact-info ms-2 me-4" *ngIf="providerData?.physician">
        <div class="name-speciality">
          <p class="h6 m-0 cursor-pointer"
            [mdbTooltip]="providerData?.physician?.displayName || providerData?.physician?.businessName || getFullName(providerData?.physician)">
            {{ providerData?.physician?.displayName || providerData?.physician?.businessName || getFullName(providerData?.physician) | slice:0:50 }}
            <span
              *ngIf="providerData?.physician?.displayName?.length > 50 || providerData?.physician?.businessName?.length > 50 || getFullName(providerData?.physician).length > 50">...</span>
          </p>
          <p class="m-0 text-light">
            <small>{{ providerData?.physician?.primaryTaxonomy && providerData?.physician?.primaryTaxonomy[0]?.name
              }}</small>
          </p>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <mdb-tabs #tabs (activeTabChange)="onTabChange($event)">
        <mdb-tab title="{{ subTitle }}">
          <div class="mb-3 px-2">
            <small class="p-1" style="background-color: #ebebeb; border-radius: 4px; font-size: 12px;">
              <i class="fas fa-info-circle text-light"></i> For help finding a physician, contact: <a class="text-primary text-decoration-underline" href="tel:+2628346126">262-834-6126</a>
            </small>
          </div>
          <div class="my-3" *ngIf="isLoading.physicianList && !physicianList">
            <app-loader [loader]="true"></app-loader>
          </div>
          <div class="row mx-1 g-2" *ngIf="!isLoading.physicianList && physicianList.length > 0">
            <div *ngFor="let item of physicianList" class="col-6 col-sm-4 col-md-4 col-lg-4 p-1 m-0">
              <div class="card" style="border: 1px solid #ddd; border-radius: 4px; min-height: 217px;">
                <div class="d-flex flex-column justify-content-center align-items-center card-body">
                  <div class="contact-img mb-2">
                    <i class="mx-auto d-block img-fluid rounded-circle shadow-4 fa-solid fa-user-doctor fa-2x text-primary d-flex align-items-center justify-content-center"
                      style="height: 60px; width: 60px;"></i>
                  </div>
                  <div class="name-speciality text-center">
                    <p class="h6 m-0 mt-1 cursor-pointer" [mdbTooltip]="item?.name">
                      {{item?.name | slice:0:20}}
                      <span *ngIf="item?.name?.length > 20">...</span>
                    </p>
                    <p class="m-0 text-light" [mdbTooltip]="item.adaptedPrimaryTaxonomy">
                      <small>{{ item.adaptedPrimaryTaxonomy | slice:0:20 }}</small>
                      <span *ngIf="item.adaptedPrimaryTaxonomy > 20">...</span>
                    </p>
                    <p *ngIf="subTitle == 'Facility' && item?.phone" class="m-0 text-light" [mdbTooltip]="item?.phone">
                      <i class="fa-solid fa-mobile-button fa-sm text-light me-2"></i><small>{{ item?.phone ? (item.phone | mask: '000.000.0000') : 'N/A' }}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
            *ngIf="!isLoading.physicianList && !physicianList">
            <div class="text-center mt-3">
              <i class="fa-solid fa-user-doctor fa-2xl text-primary"></i>
              <p class="mt-2"><small>{{ subTitle }} not found.</small></p>
            </div>
          </div>
        </mdb-tab>
        <mdb-tab title="Services">
          <div class="mb-3 px-2">
            <small class="p-1" style="background-color: #ebebeb; border-radius: 4px; font-size: 12px;">
              <i class="fas fa-info-circle text-light"></i> Learn more about starting an Episode of Care: <a class="text-primary text-decoration-underline cursor-pointer" (click)="onLinkClick(HOLISTA_CONSTANT.HOLISTA_GET_STARTED, true)">Let’s Get Started</a>
            </small>
          </div>
          <div class="my-3" *ngIf="isLoading.serviceList && !serviceList.length">
            <app-loader [loader]="true"></app-loader>
          </div>
          <div class="row mx-1 g-2" *ngIf="!isLoading.serviceList && serviceList.length">
            <div class="d-flex col-12 col-sm-6 col-md-6 col-lg-6 service-list" *ngFor="let item of serviceList">
              <div *ngIf="item.displayName">
                <i class="fa-regular fa-circle-check text-primary" style="margin-top: 4px;"></i>
                <small class="m-0 ms-2">{{item.displayName || item.bundleName}}</small>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center py-4 border no-conversation-container h-100"
            *ngIf="!isLoading.serviceList && !serviceList.length">
            <div class="text-center mt-3">
              <i class="fa-solid fa-calendar-days fa-2xl text-primary"></i>
              <p class="mt-2"><small>Services not found.</small></p>
            </div>
          </div>
        </mdb-tab>
      </mdb-tabs>
    </div>
  </div>
</div>